


import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Link from '@mui/material/Link';
import exampleImage from './logo-white.png';
import logo from '../logo/logo-no-background.png'
import {  Box, Container, Grid, Paper,ButtonGroup, Toolbar, Typography, Button, IconButton, Badge, Chip, Card, CardContent, CardActions, CardMedia } from '@mui/material';
import LoadingScreen from '../LoadingScreen';
import {  Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { collection, getDocs,getDoc, doc, query, where, addDoc } from 'firebase/firestore';
import { db } from '../firebase-config'; // Adjust path as needed

import { getAuth, onAuthStateChanged ,signOut} from 'firebase/auth';
import { onSnapshot } from 'firebase/firestore';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { useSearchParams } from 'react-router-dom';
import StickyFooter from '../StickyFooter'; 




// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const drawerWidth = 240;


const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#304f6d', 
    },
  },
});

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [products, setProducts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState("Inactive"); 
  const [loading, setLoading] = useState(false);
  const [currentBot, setCurrentBot] = useState(null);
  const [activeBots, setActiveBots] = useState([]);
  const [bots, setBots] = useState([]);
  const [selectedBot, setSelectedBot] = useState('All Bots');
  let [searchParams, setSearchParams] = useSearchParams();

  const [anchorEl, setAnchorEl] = React.useState(null); // For the account icon menu

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      //console.log('User signed out successfully');
      // Navigate the user to the sign-in page or update the UI accordingly
    }).catch((error) => {
      //console.error('Sign out error', error);
    });
  };

  useEffect(() => {

 
    const fetchProductsAndActiveSubscriptions = async (userId) => {
      //console.log("Fetching products and determining active subscriptions for user:", userId);
      
      const activeSubscriptions = [];
      const productsQuery = query(collection(db, 'products'), where('active', '==', true));
      const productsSnapshot = await getDocs(productsQuery);
      const productsList = await Promise.all(productsSnapshot.docs.map(async (productDoc) => {
        const productData = productDoc.data();
        const priceQuery = query(collection(productDoc.ref, 'prices'));
        const priceSnapshot = await getDocs(priceQuery);
        const priceData = priceSnapshot.docs[0].data();
    
        if (productData.metadata?.bot) {
          const botName = productData.metadata.bot;
          const subscriptionDocRef = doc(db, 'subscription_tokens', userId, botName, productDoc.id);
          const subscriptionDoc = await getDoc(subscriptionDocRef);
          
          if (subscriptionDoc.exists() && subscriptionDoc.data().isActive) {
            activeSubscriptions.push({
              botName: botName,
              productId: productDoc.id,
              ...subscriptionDoc.data()
            });
          }
        }
    
        return {
          id: productDoc.id,
          ...productData,
          priceId: priceSnapshot.docs[0].id,
          priceInfo: priceData,
          productType: priceData.type === 'recurring' ? 'Subscription' : 'one_time',
          isActive: activeSubscriptions.some(sub => sub.botName === productData.metadata?.bot && sub.productId === productDoc.id)
        };
      }));
    
      //console.log("Active subscriptions:", activeSubscriptions);
      //console.log("Updated products list:", productsList);
    
      setProducts(productsList);
      const botsWithStatus = productsList.map(product => ({
        name: product.metadata?.bot,
        isActive: activeSubscriptions.some(sub => sub.botName === product.metadata?.bot)
      })).filter((value, index, self) => self.findIndex(bot => bot.name === value.name) === index);
    
      //console.log("Bots with status:", botsWithStatus);
      //console.log('Active subscriptions:', activeSubscriptions);

      setBots(botsWithStatus);

    };


    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        //console.log(user)
        fetchProductsAndActiveSubscriptions(user.uid);
        //await fetchActiveBots(user.uid);
        
        //await fetchSubscriptionStatus(user.uid); // Fetch subscription status for logged-in user
      } else {
        // User is signed out
        setCurrentUser(null);
        setBots([]); 
        setSubscriptionStatus("Inactive"); // Reset subscription status
      }
      //fetchProducts(); // This can be outside the if-else since it's not dependent on user status
    });
  
    const bot = searchParams.get('bot') || sessionStorage.getItem('bot');
    setCurrentBot(bot); // This will be null if no bot is specified
    sessionStorage.removeItem('bot'); 
    
    
    
    //fetchProducts();
    }, [searchParams]); 

  
  const handleCreatePaymentLink = async (paymentType, priceId, successLink) => {
    setLoading(true); // Assuming setLoading updates some state or indicator in your UI
    const currentUser = getAuth().currentUser;

    if (!currentUser || !priceId) {
        console.log("Missing user or priceId:", currentUser, priceId);
        setLoading(false);
        return;
    }

    let checkoutSessionData = {
        price: priceId,
        //allow_promotion_codes: true,
        success_url: successLink, // Customize as needed
        cancel_url: `${window.location.origin}/`, // Customize as needed
        mode: paymentType === "Subscription" ? 'subscription' : 'payment', // Dynamically set mode based on paymentType
       
    };

    try {
        const docRef = await addDoc(
            collection(db, `customers/${currentUser.uid}/checkout_sessions`),
            checkoutSessionData
        );

        // Listen for changes in the newly created checkout session
        onSnapshot(docRef, (doc) => {
            const { error, url } = doc.data();
            if (error) {
                console.error("Checkout session error:", error);
                setLoading(false); // Stop loading process if there is an error
                // Optionally, inform the user about the error.
                return; // Exit the function if there is an error
            }
            if (url) {
                window.location.assign(url); // Redirect to Stripe checkout if URL is provided
            }
        });
    } catch (error) {
        console.error("Error creating checkout session:", error);
        setLoading(false); // Stop loading process if there is an error
    }
};

// const fetchCustomerPortalLink = async () => {
//   setLoading(true)

//   const createPortalLink = httpsCallable(
//     functions, 
//     'ext-firestore-stripe-payments-ezj4-createPortalLink');


//   const { data } = await  createPortalLink({
//     returnUrl: window.location.origin,
//     locale: "auto", // Optional, defaults to "auto"
//     //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
//   });
//   window.location.assign(data.url);
//   setLoading(false)
  
// }


  const filteredProducts = products.filter(product => currentBot === null || product.metadata?.bot === currentBot);

  return (
    <ThemeProvider theme={defaultTheme}>
      {loading && <LoadingScreen />}
      <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      
       

        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" sx={{ mb: 4 }}>
              Explore Our Products
            </Typography>
                    {/* Bot Selector */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h6">Select a Bot:</Typography>
                <ButtonGroup variant="text" aria-label="text button group">
                {bots.map(bot => (
                  <Button 
                    key={bot.name} // Assuming bot is an object with a 'name' property
                    onClick={() => setCurrentBot(bot.name)}
                    color={currentBot === bot.name ? "secondary" : "primary"}
                    sx={{
                      border: activeBots.includes(bot.name) ? '2px solid green' : '', // Highlight edges for active bots
                      marginRight: '8px', // Add some space between buttons
                      '&:hover': {
                        border: activeBots.includes(bot.name) ? '2px solid darkgreen' : '', // Darken on hover for active bots
                      },
                    }}
                  >
                    {bot.name}
                    {bot.isActive && <Chip label="Active" color="success" size="small" sx={{ ml: 1 }} />}
                    
                  </Button>
                ))}
                  <Button onClick={() => setCurrentBot(null)} color={!currentBot ? "secondary" : "primary"}>
                    Show All
                  </Button>
                </ButtonGroup>
            </Box>
            <Grid container spacing={4}>
              
              {products.filter(product => !currentBot || product.metadata.bot === currentBot).map((product) => (


                <Grid item xs={12} sm={6} lg={4} key={product.id}>
                  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <CardMedia
                    component="img"
                    sx={{ height: 160 }}
                    image={product.images && product.images.length > 0 ? product.images[0] : exampleImage}
                    alt={product.name}
                  />

                    
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {product.name}
                      </Typography>
                      <Typography>
                        {product.description}
                      </Typography>
                      <Typography>
                        {product.metadata.price}€
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="contained" onClick={() => handleCreatePaymentLink(product.productType, product.priceId, product.metadata.link)} disabled={loading}>
                        {loading ? 'Processing...' : 'Buy Now'}
                        
                      </Button>
                      
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        
      </Box>
      <StickyFooter />
    </ThemeProvider>
  );

}


