import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const OAuth = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [idToken, setIdToken] = useState(null); // State to hold the ID token
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setCurrentUser(user);
                console.log("User is signed in:", user);
                user.getIdToken().then(token => {
                    setIdToken(token); // Save the ID token in state
                    localStorage.setItem('idToken', token); // Optionally save it in local storage
                    
                });
                initiateOAuth(); 
            } else {
                console.log("No user signed in.");
                setCurrentUser(null);
                setIdToken(null); // Clear ID token when signed out
                localStorage.removeItem('idToken'); // Clear local storage
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const initiateOAuth = () => {
        // if (!currentUser) {
        //     console.error('No user signed in to initiate OAuth.');
        //     return;
        // }
        const scope = encodeURIComponent('https://www.googleapis.com/auth/calendar');
        const clientId = "501471273077-o1pmqsnepk5ci7h9pmfg93e5lntupgue.apps.googleusercontent.com";
        //const redirectUri = "http://localhost:3000/oauthcallback";
        const redirectUri = "https://app.pandorabot.io/oauthcallback";
        const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&access_type=offline&prompt=consent`;
        window.location.href = authUrl;
    };

    return (
        <div>
            <button onClick={initiateOAuth} disabled={!currentUser || !idToken}>Authenticate with Google</button>
        </div>
    );
};

export default OAuth;
