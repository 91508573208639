import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const auth = getAuth(); // Getting the Firebase Auth instance

  useEffect(() => {
    console.log("Setting up auth state change listener");
    
    const handleAuthStateChange = user => {
      if (user) {
        console.log("User is signed in: ", user);
        user.getIdToken().then(idToken => {
          console.log("ID Token: ", idToken);
          const urlParams = new URLSearchParams(window.location.search);
          const code = urlParams.get('code');
          console.log("Authorization code from URL: ", code);
          
          if (!code) {
            console.error('Authorization code not found.');
            navigate('/error'); // Navigate to an error page or show an error message
            return;
          }

          // Use the code and ID token to request tokens from the backend
          console.log("Sending POST request to backend to exchange code for tokens");
          //fetch('https://us-central1-stripe-cde79.cloudfunctions.net/oauthcallback', {
            fetch('https://app.pandorabot.io/oauthcallback',{
            method: 'POST',
            mode: "cors",
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}` // Use the ID token here
            },
            body: JSON.stringify({
              code: code,
              userId: user.uid // Use the UID from the authenticated user
            }),
            mode: 'cors', // Enable CORS
            credentials: 'include' 
          })
          .then(response => {
            console.log("Response received from the server");
            if (!response.ok) throw new Error(`Failed to exchange code for tokens, status: ${response.status}`);
            //return response.json(); // Assuming the server responds with JSON
            console.log("here")
          })
          .then(() => {
            console.log("Token exchange successful, navigating to '/calendar'");
            navigate('/calendar') // Navigate to the calendars page upon successful token exchange
          })
          .catch(error => {
            console.error('Error during code exchange:', error);
            navigate('/error'); // Navigate to an error page or handle the error accordingly
          });
        });
      } else {
        console.error('No user is signed in.');
        navigate('/login'); // Redirect to login page if not logged in
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);
    return () => {
      console.log("Cleaning up auth state change listener");
      unsubscribe(); // Clean up the subscription
    };
  }, [navigate, auth]);

  return <div>Loading, please wait...</div>;
};

export default OAuthCallback;
